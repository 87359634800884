enum FIELD_TYPE {
  DATE = "DATE",
  INPUT = "INPUT",
  COMBO_BOX_ADD = "COMBO_BOX_ADD",
  INPUT_NUMBER = "INPUT_NUMBER",
  INPUT_PASSWORD = "INPUT_PASSWORD",
  SELECT = "SELECT",
  SELECT_MULTI = "SELECT_MULTI",
  CHECKBOX = "CHECKBOX",
  LIST = "LIST",
  RADIO = "RADIO",
  RADIO_VN = "RADIO_VN",
  RADIO_BUTTON = "RADIO_BUTTON",
  COLOR = "COLOR",
  NUMBER = "NUMBER",
  AUTO_COMPLETE = "AUTO_COMPLETE",
  COMBO_BOX = "COMBO_BOX",
  TEXT_AREA = "TEXT_AREA",
  BUTTON = "BUTTON",
  ICON = "ICON",
  RADIO_CUSTOM = "RADIO_CUSTOM",
  NORMAL_CHECKBOX = "NORMAL_CHECKBOX",
  NORMAL_CHECKBOX_NONE = "NORMAL_CHECKBOX_NONE",
  TEXTT_AREA_CUSTOM = "TEXTT_AREA_CUSTOM",
  SWITCH = "SWITCH",
  UPLOAD = "UPLOAD",
}
export { FIELD_TYPE };
