import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { API_METHOD } from "types/api-method";
import { baseQueryWithReauth } from "types/api/base-query";

const { POST } = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "generateFileAPI";

export const generateFileAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    generateFileAPI: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: apiPath.GENERATEFILE,
          method: POST,
          data,
        };
      },
    }),
  }),
});

export const generateFileAPIReducer = generateFileAPI.reducer;

export const { useGenerateFileAPIMutation } = generateFileAPI;
