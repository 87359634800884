import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQueryWithReauth } from "types/api/base-query";

const { GET } = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "downFileAPI";

export const downFileAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    downFile: builder.query<
      any,
      { classCode: string | number | undefined | null }
    >({
      query: (data) => {
        return {
          url: `${apiPath.DOWNFILE}${serialize(data)}`,
          method: GET,
          responseType: "blob",
        };
      },
    }),
  }),
});

export const downFileAPIReducer = downFileAPI.reducer;

export const { useDownFileQuery } = downFileAPI;
