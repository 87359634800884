import { downFile } from "./downFile";
import { course } from "./cource";
import { exam } from "./exam";
import { topic } from "./topic";
import { part } from "./part";
import { question } from "./question";
import { file } from "./file";
import { grading } from "./grading";
import { result } from "./result";
import { auth } from "./auth";
import { candidate } from "./candidate";
import { gradingEssay } from "./gradingEssay";
import { activeUser } from "./active-user";
import { user } from "./user";
import { changePassword } from "./changePassword";
import { forgotPassword } from "./forgotPassword";
import { generateFile } from "./generateFile";
import { forwardLink } from "./forwardLink";

const apiPath = {
  ...exam,
  ...topic,
  ...course,
  ...part,
  ...question,
  ...file,
  ...grading,
  ...result,
  ...auth,
  ...candidate,
  ...gradingEssay,
  ...activeUser,
  ...user,
  ...changePassword,
  ...forgotPassword,
  ...downFile,
  ...generateFile,
  ...forwardLink,
};

export { apiPath };
