import { Navigate } from "react-router-dom";
import { ROUTER_ROUTE, USER_ROUTE } from "./routers";
import { MainLayout } from "layouts";
import lazy from "utils/lazy";
import ProtectedOutlet from "./protected-outlet";
import RoleOutlet from "./role-outlet";
import { UserLayout } from "layouts/layoutUser";
import ProtectedOutletCandidate from "./protected-outlet-user";
import { ROLE } from "types/enums/role";
// import Error404 from "pages/error/404";
// import Course from "pages/Course/Course";

const MainRoutes = [
  {
    path: USER_ROUTE.HOME,
    element: <Navigate to={USER_ROUTE.TEST_LIST} replace />,
  },
  {
    path: ROUTER_ROUTE.LOGIN,
    element: lazy({ path: "AdminPage/Login/Login" }),
  },
  {
    path: ROUTER_ROUTE.LOGIN_DOCS,
    element: lazy({ path: "UserPage/LoginDocs/index" }),
  },
  {
    path: "",
    element: <UserLayout />,
    children: [
      {
        path: USER_ROUTE.TEST_LIST,
        element: lazy({ path: "UserPage/Home/index" }),
      },
      {
        path: USER_ROUTE.DETAIL_EXAM,
        element: lazy({ path: "UserPage/Home/DetailExam/index" }),
      },
      {
        path: "",
        element: <ProtectedOutletCandidate />,
        children: [
          {
            path: USER_ROUTE.QUESTION_LIST,
            element: lazy({
              path: "UserPage/Home/DetailExam/ContentPart/index",
            }),
          },
          {
            path: USER_ROUTE.RESULT_EXAM,
            element: lazy({ path: "UserPage/Home/ResultExam/index" }),
          },
          {
            path: USER_ROUTE.GRADE_EXAM_LIST,
            element: lazy({
              path: "UserPage/Home/ResultExam/GradeExamList/index",
            }),
          },
          {
            path: USER_ROUTE.GRADE_EXAM_DETAIL,
            element: lazy({
              path: "UserPage/Home/ResultExam/GradeExamList/GradeExamDetail",
            }),
          },
        ],
      },
      {
        path: ROUTER_ROUTE.ERROR_404,
        element: lazy({ path: "error/404" }),
      },
      {
        path: "*",
        element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
      },
    ],
  },

  {
    key: "private",
    path: "",
    element: <ProtectedOutlet />,
    children: [
      //layout for role admin
      {
        path: "",
        element: <RoleOutlet listRoleRequired={[ROLE.ROLE_ADMIN]} />,
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              {
                path: ROUTER_ROUTE.EXAM,
                element: lazy({ path: "AdminPage/Exam/index" }),
              },
              {
                path: ROUTER_ROUTE.PART,
                element: lazy({
                  path: "AdminPage/Part/index",
                }),
              },
              {
                path: ROUTER_ROUTE.QUESTION,
                element: lazy({
                  path: "AdminPage/Question/index",
                }),
              },
              {
                path: ROUTER_ROUTE.QUESTION_DETAIL,
                element: lazy({
                  path: "AdminPage/QuestionDetail/index",
                }),
              },
              {
                path: ROUTER_ROUTE.USER,
                element: lazy({
                  path: "AdminPage/User/index",
                }),
              },
              {
                path: ROUTER_ROUTE.CATALOG,
                element: lazy({
                  path: "AdminPage/Catalog/index",
                }),
              },
              {
                path: ROUTER_ROUTE.GENERATED_FILE,
                element: lazy({
                  path: "AdminPage/GenerateFile/index",
                }),
              },
              {
                path: ROUTER_ROUTE.ERROR_404,
                element: lazy({ path: "error/404" }),
              },
              {
                path: "*",
                element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
              },
            ],
          },
        ],
      },
      {
        path: "",
        element: (
          <RoleOutlet
            listRoleRequired={[
              ROLE.ROLE_ADMIN,
              ROLE.ROLE_CONSULTANT,
              ROLE.ROLE_MODERATOR,
              ROLE.ROLE_CONSULTANT_CTV,
            ]}
          />
        ),
        children: [
          {
            path: "",
            element: <MainLayout />,
            children: [
              {
                path: ROUTER_ROUTE.GRADE_USER_LIST,
                element: lazy({
                  path: "GradePage/GradeUserList",
                }),
              },
              {
                path: ROUTER_ROUTE.GRADE_EXAM_LIST,
                element: lazy({
                  path: "GradePage/GradeExamList",
                }),
              },
              {
                path: ROUTER_ROUTE.GRADE_EXAM_DETAIL,
                element: lazy({
                  path: "GradePage/GradeExamDetail",
                }),
              },
              {
                path: ROUTER_ROUTE.ERROR_404,
                element: lazy({ path: "error/404" }),
              },
              {
                path: "*",
                element: <Navigate to={ROUTER_ROUTE.ERROR_404} replace />,
              },
            ],
          },
        ],
      },
    ],
  },

  // {
  //   path: ROUTER_ROUTE.ERROR_404,
  //   element: lazy({ path: "error/404" }),
  // },
];

export default MainRoutes;
