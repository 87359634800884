import { createApi } from "@reduxjs/toolkit/query/react";
import { apiPath } from "constants/apiPath";
import { serialize } from "helper/parse";
import { API_METHOD } from "types/api-method";
import { baseQueryWithReauth } from "types/api/base-query";

const { GET } = API_METHOD;

export const CANDIDATE_API_REDUCER_KEY = "fowardLinkAPI";

export const fowardLinkAPI = createApi({
  reducerPath: CANDIDATE_API_REDUCER_KEY,
  baseQuery: baseQueryWithReauth,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    fowardLinkAPI: builder.query<any, any>({
      query: (data) => {
        console.log("🚀 ~ data:", data);
        return {
          url: `${apiPath.FORWARDLINK}${serialize(data)}`,
          method: GET,
        };
      },
    }),
  }),
});

export const fowardLinkAPIReducer = fowardLinkAPI.reducer;

export const { useFowardLinkAPIQuery } = fowardLinkAPI;
