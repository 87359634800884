import { notification } from "antd";
import "./Notification.scss";

type NotificationProps = {
  status: "error" | "warning" | "success" | "info";
  message: string;
  duration?: number;
};

const Notification = ({ status, message, duration = 4 }: NotificationProps) => {
  return notification[status]({
    message: message,
    placement: "bottomLeft",
    duration,
    className: "notification",
  });
};

export { Notification };
