import { BREADCRUMB } from "types/enums/breadCrumb";
import { ROUTER_ROUTE } from "./routers";

interface MainRouteProps {
  path: string;
  label: string;
  role?: string;
}

export const MAIN_ROUTE: MainRouteProps[] = [
  // {
  //   path: ROUTER_ROUTE.COURSE,
  //   label: BREADCRUMB.COURSE_LIST,
  // },
  {
    path: ROUTER_ROUTE.TOPIC_LIST,
    label: BREADCRUMB.TOPIC_LIST,
  },
  {
    path: ROUTER_ROUTE.EXAM,
    label: BREADCRUMB.EXAM_LIST,
  },
  {
    path: ROUTER_ROUTE.PART,
    label: BREADCRUMB.CONTENT_PART,
  },
  {
    path: ROUTER_ROUTE.QUESTION,
    label: BREADCRUMB.QUESTION,
  },
  {
    path: ROUTER_ROUTE.USER,
    label: BREADCRUMB.USER,
  },
  {
    path: ROUTER_ROUTE.QUESTION_DETAIL,
    label: BREADCRUMB.QUESTION_DETAIL,
  },
  { path: ROUTER_ROUTE.GRADE_USER_LIST, label: BREADCRUMB.GRADE_USER_LIST },
  { path: ROUTER_ROUTE.GRADE_EXAM_LIST, label: BREADCRUMB.GRADE_EXAM_LIST },
  { path: ROUTER_ROUTE.GRADE_EXAM_DETAIL, label: BREADCRUMB.GRADE_EXAM_DETAIL },
];
