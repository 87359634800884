export const ROUTER_ROUTE = {
  //router for admin
  LOGIN: "/admin/login",
  COURSE: "/admin/course-list",
  TOPIC_LIST: "/admin/course-list/topic-list",
  EXAM: "/admin/exam-list",
  PART: "/admin/exam-list/part-list",
  QUESTION: "/admin/exam-list/part-list/question-list",
  GRADE_USER_LIST: "/admin/grade-user-list",
  GRADE_EXAM_LIST: "/admin/grade-user-list/grade-exam-list",
  GRADE_EXAM_DETAIL: "/admin/grade-user-list/grade-exam-list/grade-detail",
  QUESTION_DETAIL: "/admin/exam-list/part-list/question-list/question-detail",

  ERROR_404: "/error/404",
  REFRESH_TOKEN: "/auth/refreshtoken",
  SIGN_IN_WITH_EMAIL_AND_PASSWORD: "/auth/signin_admin",
  USER: "/admin/user",
  GENERATED_FILE: "/admin/generate-file",
  LOGIN_DOCS: "/login-doc",
  //danh mục
  CATALOG: "/catalog",
};

export const USER_ROUTE = {
  HOME: "/",
  TEST_LIST: "/test-list",
  DETAIL_EXAM: "/test-list/:testExam",
  QUESTION_LIST: "/question-start",
  RESULT_EXAM: "/result-exam",
  ERROR_404: "/error/404",
  GRADE_EXAM_LIST: "/grade-exam-list",
  GRADE_EXAM_DETAIL: "/grade-exam-list/grade-detail/:id",
};
