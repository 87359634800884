import { Col } from "antd";
import FormItem from "./FormItem/FormItem";
import "./InputFields.scss";
import { MultiField } from "types/models/input-field-type";

const InputFields = ({ data }: MultiField): JSX.Element => {
  const renderCol = (col: number) => {
    let xs: number = 24,
      sm: number = 24,
      md: number = 24,
      lg: number = 12,
      xl: number = 8;
    let labelCol = {};
    let wrapperCol = {};

    switch (col) {
      case 1:
        xs = 24;
        sm = 24;
        md = 24;
        lg = 24;
        xl = 24;
        labelCol = {
          xs: 24,
          sm: 10,
          md: 12,
          lg: 5,
          xl: 5,
        };

        wrapperCol = {
          xs: 24,
          sm: 14,
          md: 12,
          lg: 19,
          xl: 19,
        };
        break;

      case 2:
        xs = 24;
        sm = 24;
        md = 12;
        lg = 12;
        xl = 12;
        wrapperCol = { xs: 14, md: 16, xl: 18, lg: 16 };
        labelCol = { xs: 10, md: 8, xl: 6, lg: 8 };
        break;
      case 3:
        xs = 24;
        sm = 24;
        md = 12;
        lg = 8;
        xl = 8;
        wrapperCol = { xs: 14, md: 16, xl: 18, lg: 16 };
        labelCol = { xs: 10, md: 8, xl: 6, lg: 8 };
        break;

      default:
        break;
    }

    return { xs, sm, md, lg, xl, labelCol, wrapperCol };
  };

  return (
    <>
      {data?.map(
        (
          {
            type,
            label = "",
            placeholder,
            disabled = false,
            suffix,
            checkbox,
            options,
            optionsTime,
            rules,
            name,
            css = { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
            // wrapperCol = { xs: 14, md: 16, xl: 18, lg: 16 },
            // labelCol = { xs: 10, md: 8, xl: 6, lg: 8 },
            className,
            addonAfter,
            radioButtons,
            value,
            onChange,
            onSearch,
            statusDrug,
            defaultValue,
            radioCustom,
            disableDate,
            disabledDate,
            initialValue,
            hide,
            mode,
            allowClear,
            changeOnBlur,
            loading,
            onOpenChange,
            formatter,
            needConfirm,
            onClick,
            btn,
            col = 3,
            propsupload,
            icon,
            ...rest
          },
          index
        ) => {
          // const { xs, sm, md, xl, lg } = css;
          const { xs, sm, md, xl, lg, labelCol, wrapperCol } = renderCol(col);

          return (
            <Col
              className={`input-fields`}
              xs={xs}
              sm={sm}
              md={md}
              xl={xl}
              lg={lg}
              key={index}
              style={{ display: hide ? "none" : "" }}
            >
              <FormItem
                suffix={suffix}
                onOpenChange={onOpenChange}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                options={options}
                labelCol={labelCol}
                wrapperCol={wrapperCol}
                checkbox={checkbox}
                rules={rules}
                name={name}
                label={label}
                className={className}
                optionsTime={optionsTime}
                addonAfter={addonAfter}
                radioButtons={radioButtons}
                value={value}
                onChange={onChange}
                onSearch={onSearch}
                statusDrug={statusDrug}
                defaultValue={defaultValue}
                radioCustom={radioCustom}
                disableDate={disableDate}
                disabledDate={disabledDate}
                initialValue={initialValue}
                allowClear={allowClear}
                mode={mode}
                changeOnBlur={changeOnBlur}
                formatter={formatter}
                needConfirm={needConfirm}
                onClick={onClick}
                btn={btn}
                propsUpload={propsupload}
                icon={icon}
                {...rest}
              />
            </Col>
          );
        }
      )}
    </>
  );
};

export default InputFields;
